import React, { useEffect, useState } from "react";
import styles from "./locations.module.css";
import Heading from "../../components/heading/Heading";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import { GeneralData } from "../../data/GeneralData";

const Locations = () => {
  const [locations, setLocations] = useState({});

  useEffect(() => {
    setLocations(GeneralData.locations);
  }, []);

  return (
    <div className={styles.locations_container}>
      <AnimatedSection>
        <Heading
          title="Training Locations"
          description="Explore our diverse training locations"
          hcolor="#0d141a"
          pcolor="#56585e"
        />
      </AnimatedSection>

      <div className={styles.locations_content_container}>
        {Object.keys(locations).map((loc) => (
          <AnimatedSection>
            <div className={styles.locations_map_container} key={loc}>
              <div className={styles.locations_info_container}>
                <h4>{loc}</h4>
                <p>{locations[loc]["timings"]}</p>
              </div>
              <div className={styles.locations_map}>
                <iframe
                  title="Google Maps"
                  src={locations[loc]["embed"]}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  referrerpolicy="no-referrer-when-downgrade"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </AnimatedSection>
        ))}
      </div>
    </div>
  );
};

export default Locations;
