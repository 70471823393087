import React from "react";
import ContactUs from "../../sections/contactUs/ContactUs";
import NavSpacer from "../../components/navSpacer/NavSpacer";

const NotFound = () => {
  return (
    <div>
      <NavSpacer />
      <div
        style={{
          backgroundColor: "antiquewhite",
          padding: "30px",
        }}
      >
        NotFound
      </div>
      <ContactUs />
    </div>
  );
};

export default NotFound;
