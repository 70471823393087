import React, { useState } from "react";
import styles from "./nav.module.css";
import logoBlack from "../../images/logoblack.png";
import { Link, NavLink } from "react-router-dom";
import ToggleButton from "../../components/toggleButton/ToggleButton";

const Nav = () => {
  const [navToggle, setNavToggle] = useState(false);

  return (
    <div className={styles.nav_container}>
      <div className={styles.nav_content_container}>
        <Link to={"/"}>
          <img src={logoBlack} alt="Logo" />
        </Link>

        <div className={navToggle && styles.nav_open}>
          <ToggleButton navToggle={navToggle} setNavToggle={setNavToggle} />
        </div>

        <div className={styles.nav_links_container}>
          <NavLink to={"/"} className={styles.nav_link}>
            Home
          </NavLink>
          <NavLink to={"about-us"} className={styles.nav_link}>
            Aboout Us
          </NavLink>
          <NavLink to={"/sessions"} className={styles.nav_link}>
            Sessions
          </NavLink>
          <NavLink to={"/testimonials"} className={styles.nav_link}>
            Testimonials
          </NavLink>
          <NavLink to={"/faq"} className={styles.nav_link}>
            FAQ
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Nav;
