import burjImg from "../images/burj.jpg";
import jumpImg from "../images/jumping.jpg";
import trainingImg from "../images/training.jpg";

export const CardsData = {
  "Training Program": {
    title: "Training Program",
    description: "Training Program Details",
    link: `training-program`,
    image: trainingImg,
  },
  "Burj Exam": {
    title: "Burj Exam",
    description: "Burj Exam Details",
    link: `burj-exam`,
    image: burjImg,
    // projects: {
    //   a: {
    //     "Parachuting First Jump": {
    //       title: "Parachuting First Jump",
    //       image: "",
    //       content: {
    //         // key: []
    //       },
    //     },
    //   },
    // },
  },
  "Jump Day": {
    title: "Jump Day",
    description: "Jump Day Details",
    link: `jump-day`,
    image: jumpImg,
  },
};
