import React, { useEffect, useState } from "react";
import styles from "./header.module.css";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import Button from "../../components/button/Button";
import { GeneralData } from "../../data/GeneralData";

const Header = () => {
  const [heroImg, setHeroImg] = useState("");
  useEffect(() => {
    setHeroImg(GeneralData.hero.image);
  }, []);
  return (
    <div
      className={styles.header_container}
      style={{ backgroundImage: heroImg }}
    >
      <div className={styles.header_content_container}>
        <AnimatedSection>
          <h2>Prepare For The Ultimate Adventure</h2>
        </AnimatedSection>

        <AnimatedSection>
          <p>Join our expert-led parachute training sessions!</p>
        </AnimatedSection>

        <AnimatedSection>
          <Button
            title={"Enroll Now"}
            bgcolor={"#1e3a8a"}
            hoverbgcolor={"transparent"}
            fontcolor={"#ffffff"}
            hoverfontcolor={"#ffffff"}
            link={"/contact"}
          />
        </AnimatedSection>
      </div>
    </div>
  );
};

export default Header;
