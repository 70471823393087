import React from "react";
import styles from "./keyFeatures.module.css";
import Heading from "../../components/heading/Heading";
import featuresImg from "../../images/hero.png";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";

const keyFeatures = () => {
  return (
    <div className={styles.features_container}>
      <AnimatedSection>
        <Heading
          title="Key Features"
          description="Discover our comprehensive parachute training for all skill levels"
          hcolor="#0d141a"
          pcolor="#56585e"
        />
      </AnimatedSection>
      <div className={styles.features_content_container}>
        <div className={styles.features_info}>
          <AnimatedSection>
            <h3>Fitness Training</h3>
            <p>Get fit and ready for your jump with our specialized programs</p>
            <h3>Technique Training</h3>
            <p>Learn all the techniques required to jump and land safely</p>
            <h3>Burj Test Preparation</h3>
            <p>
              A required test done by the military to be allowed to parachute
            </p>
          </AnimatedSection>
        </div>

        <div className={styles.features_img_container}>
          <AnimatedSection>
            <img src={featuresImg} alt="group" />
          </AnimatedSection>
        </div>
      </div>
    </div>
  );
};

export default keyFeatures;
