import React, { useEffect, useState } from "react";
import styles from "./footer.module.css";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import { GeneralData } from "../../data/GeneralData";

const Footer = () => {
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setPhoneNumber(GeneralData.contact["Phone Number"][0]);
  }, []);

  return (
    <div className={styles.footer_container}>
      <div className={styles.footer_content_container}>
        <AnimatedSection>
          <div className={styles.footer_a}>
            <h3>Training</h3>
            <p className={styles.footer_description}>
              Join us for expert led parachuting training today!
            </p>
          </div>
        </AnimatedSection>

        <AnimatedSection>
          <div className={styles.footer_b}>
            <h4>Contact</h4>
            <p>contact@sky7egypt.com</p>
            <p>{phoneNumber}</p>
          </div>
        </AnimatedSection>

        <AnimatedSection>
          <div className={styles.footer_c}>
            <h4>Excitement</h4>
            <form className={styles.footer_form}>
              <input
                type="number"
                placeholder="Enter Your Phone Number"
                required
              />
              <input type="submit" className={styles.footer_submit} />
            </form>
          </div>
        </AnimatedSection>
      </div>

      <AnimatedSection>
        <p className={styles.footer_copy}>© 2024. All rights reserved.</p>
      </AnimatedSection>
    </div>
  );
};

export default Footer;
