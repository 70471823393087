import { Route, Routes } from "react-router-dom";
import "./App.css";
import Nav from "./sections/nav/Nav";
import Home from "./pages/home/Home";
import NotFound from "./pages/notFound/NotFound";
import Footer from "./sections/footer/Footer";
import Testimonials from "./pages/testimonials/Testimonials";
import AboutUs from "./pages/aboutUs/AboutUs";
import Faq from "./pages/Faq/Faq";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import Sessions from "./pages/sessions/Sessions";
import Contact from "./pages/contact/Contact";

function App() {
  // - Key Features Redesign Concept
  // - Sessions Page Idea
  return (
    <div className="App">
      <Nav />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/sessions" element={<Sessions />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
