import React, { useEffect, useState } from "react";
import styles from "./contactUs.module.css";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import { GeneralData } from "../../data/GeneralData";

const ContactUs = () => {
  const [contact, setContact] = useState({});

  useEffect(() => {
    setContact(GeneralData.contact);
  }, []);

  return (
    <div className={styles.contact_container}>
      <div className={styles.contact_content_container}>
        <div className={styles.contact_content}>
          <AnimatedSection>
            <div className={styles.contact_heading}>
              <h3>Contact Us</h3>
              <p>
                Get in touch for inquiries about our parachuting training
                programs.
              </p>
            </div>
          </AnimatedSection>
          <AnimatedSection>
            <div className={styles.contact_info}>
              {Object.keys(contact).map((e) => (
                <div>
                  <h4>{e}</h4>
                  {contact[e].map((i) => (
                    <div>
                      <p>{i}</p>
                    </div>
                  ))}
                  {/* <p>{contact[e]}</p> */}
                </div>
              ))}
            </div>
          </AnimatedSection>
        </div>
        <form className={styles.contact_form}>
          <AnimatedSection>
            <input type="text" placeholder="Enter your Name" required />
            <input type="email" placeholder="Enter your Email" required />
            <input type="text" placeholder="Enter your Message" required />
            <input type="submit" className={styles.contact_submit} />
          </AnimatedSection>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
