import React from "react";
import NavSpacer from "../../components/navSpacer/NavSpacer";
import Locations from "../../sections/locations/Locations";
import ContactUs from "../../sections/contactUs/ContactUs";
import KeyFeatures from "../../sections/keyFeatures/KeyFeatures";

const AboutUs = () => {
  return (
    <div>
      <NavSpacer />
      <div
        style={{
          backgroundColor: "antiquewhite",
          padding: "30px",
        }}
      >
        Work in Progress!
      </div>
      <KeyFeatures />
      <Locations />
      <ContactUs />
    </div>
  );
};

export default AboutUs;
