import React, { useEffect, useState } from "react";
import Cards from "../../sections/cards/Cards";
import NavSpacer from "../../components/navSpacer/NavSpacer";
import ContactUs from "../../sections/contactUs/ContactUs";
import { CardsData } from "../../data/CardsData";

const Sessions = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(CardsData);
  }, []);
  return (
    <div>
      <NavSpacer />
      <div
        style={{
          backgroundColor: "antiquewhite",
          padding: "30px",
        }}
      >
        Work in Progress!
      </div>
      <Cards data={data} />
      <ContactUs />
    </div>
  );
};

export default Sessions;
