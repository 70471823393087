import testimonialImg1 from "../images/g1.jpg";
import testimonialImg2 from "../images/g2.jpg";
import testimonialImg3 from "../images/g3.jpg";
import testimonialImg4 from "../images/g4.jpg";
import testimonialImg5 from "../images/g5.jpg";
import testimonialImg6 from "../images/g6.jpg";
import heroImg from "../images/hero.png";

export const GeneralData = {
  hero: {
    image: heroImg,
  },
  testimonials: {
    images: [
      testimonialImg1,
      testimonialImg2,
      testimonialImg3,
      testimonialImg6,
      testimonialImg4,
      testimonialImg5,
    ],
  },
  locations: {
    "6th of October Club": {
      timings: "Tue, Fri 7-9pm",
      embed:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3590.945308500072!2d30.948147565758408!3d29.980942647479235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145856fda307cdaf%3A0xe0963aeb7fc1b547!2s6th%20of%20October%20City%20Sporting%20Club!5e1!3m2!1sen!2sus!4v1725113884046!5m2!1sen!2sus",
    },
    "Nasr City (Stadium)": {
      timings: "Sun, Tue, Thu 7-9pm",
      embed:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10147.839776262332!2d31.303122419996964!3d30.067733797286007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583f6aa8216789%3A0x328201353f819182!2sEagle%20Nest%20Egypt!5e1!3m2!1sen!2sus!4v1725113936996!5m2!1sen!2sus",
    },

    "Downtown (Lycee School)": {
      timings: "Sat, Wed 7-9pm",
      embed:
        "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d602.2656539077182!2d31.237387731962237!3d30.042256506824117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAyJzMxLjYiTiAzMcKwMTQnMTQuNiJF!5e1!3m2!1sen!2sae!4v1725141395116!5m2!1sen!2sae",
    },
  },
  contact: {
    "Phone Number": ["+20 123-456-789", "+20 123-456-789"],
    Email: ["contact@sky7egypt.com"],
  },
};
