import React, { useEffect, useState } from "react";
import styles from "./gallery.module.css";
import Heading from "../../components/heading/Heading";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import { GeneralData } from "../../data/GeneralData";

const Gallery = () => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    setImages(GeneralData.testimonials.images);
  }, []);

  return (
    <div className={styles.gallery_container}>
      <AnimatedSection>
        <Heading
          title="Trainee Experiences"
          description="Explore our gallery showcasing thrilling moments from our training sessions."
          hcolor="#0d141a"
          pcolor="#56585e"
        />
      </AnimatedSection>
      <AnimatedSection>
        <div className={styles.gallery_content_container}>
          {images.map((e) => (
            <img src={e} alt="paratrooper experience" key={e} />
          ))}
        </div>
      </AnimatedSection>
    </div>
  );
};

export default Gallery;
