import React from "react";
import FAQ from "../../sections/Qs/Qs";
import NavSpacer from "../../components/navSpacer/NavSpacer";

const Faq = () => {
  return (
    <div>
      <NavSpacer />
      <FAQ />
    </div>
  );
};

export default Faq;
