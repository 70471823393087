import React from "react";
import Gallery from "../../sections/gallery/Gallery";
import ContactUs from "../../sections/contactUs/ContactUs";
import NavSpacer from "../../components/navSpacer/NavSpacer";

const Testimonials = () => {
  return (
    <div>
      <NavSpacer />
      <Gallery />
      <ContactUs />
    </div>
  );
};

export default Testimonials;
