import React from "react";
import Header from "../../sections/header/Header";
import ContactUs from "../../sections/contactUs/ContactUs";
import Gallery from "../../sections/gallery/Gallery";
import Locations from "../../sections/locations/Locations";
import KeyFeatures from "../../sections/keyFeatures/KeyFeatures";

const Home = () => {
  return (
    <div>
      <Header />
      <KeyFeatures />
      <Locations />
      <Gallery />
      <ContactUs />
    </div>
  );
};

export default Home;
