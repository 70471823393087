import React, { useState } from "react";
import styles from "./button.module.css";
import { useNavigate } from "react-router-dom";

const Button = ({
  title,
  bgcolor,
  hoverbgcolor,
  fontcolor,
  hoverfontcolor,
  link,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <button
        className={styles.button}
        style={{
          backgroundColor: isHovered ? hoverbgcolor : bgcolor,
          borderColor: bgcolor,
          color: isHovered ? hoverfontcolor : fontcolor,
        }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={() => {
          navigate(link);
        }}
      >
        {title}
      </button>
    </div>
  );
};

export default Button;
