import React from "react";
import NavSpacer from "../../components/navSpacer/NavSpacer";
import ContactUs from "../../sections/contactUs/ContactUs";
import Heading from "../../components/heading/Heading";

const Contact = () => {
  return (
    <div>
      <NavSpacer />
      {/* <div style={{ backgroundColor: "aqua" }}>
        <Heading
          title={"Join us Now"}
          description={"First Session is Free!"}
          hcolor="#0d141a"
          pcolor="#56585e"
        />
      </div> */}
      <ContactUs />
    </div>
  );
};

export default Contact;
